import {
    Button,
    createStyles,
    makeStyles,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
} from "@material-ui/core"
import { SettingsApplications } from "@material-ui/icons"
import { observer } from "mobx-react"
import React, { useEffect } from "react"
import { Link } from "react-router-dom"
import { LinkButton, LinkIconButton } from "../../generic/atoms/LinkButton"
import { Loader } from "../../generic/atoms/Loader"
import { useGenericStyles } from "../../generic/GenericStyles"
import { RoutePaths } from "../../routing/RoutePaths"
import { USStateToName } from "../../shared/USState"
import { schoolStore } from "../stores/SchoolStore"

const useStyles = makeStyles(() =>
    createStyles({
        schoolsBar: {
            display: "flex",
            width: "660px",
        },
    }),
)

export const SchoolsPage = observer(() => {
    const { grow } = useGenericStyles()
    const styles = useStyles()
    const { schools } = schoolStore
    const [isDisplayingArchived, setIsDisplayingArchived] =
        React.useState(false)

    useEffect(() => {
        if (!schools.subscribed) {
            schoolStore.subscribeToSchools()
        }
    }, [schools.subscribed])

    if (schools.loading || schools.value == null) {
        return <Loader />
    }

    const displaySchools = schools.value.filter(
        (school) => Boolean(school.archived) === isDisplayingArchived,
    )

    return (
        <div>
            <div className={styles.schoolsBar}>
                <Typography variant={"h4"} className={grow}>
                    Schools
                </Typography>
                <Button
                    variant={"outlined"}
                    color={"primary"}
                    style={{ marginRight: 8 }}
                    onClick={() =>
                        setIsDisplayingArchived(!isDisplayingArchived)
                    }
                >
                    {isDisplayingArchived ? "Show Active" : "Show Archived"}
                </Button>

                <LinkButton
                    to={RoutePaths.createSchool}
                    variant={"contained"}
                    color={"primary"}
                >
                    Create School
                </LinkButton>
            </div>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>SCHOOL NAME</TableCell>
                        <TableCell>DISTRICT</TableCell>
                        <TableCell>CITY, STATE</TableCell>
                        <TableCell />
                    </TableRow>
                </TableHead>
                {displaySchools.length > 0 ? (
                    <TableBody>
                        {displaySchools.map((school) => (
                            <TableRow key={school.id}>
                                <TableCell>
                                    <Link
                                        to={RoutePaths.schoolDetails(school.id)}
                                    >
                                        {school.name}
                                    </Link>
                                </TableCell>
                                <TableCell>{school.district}</TableCell>
                                <TableCell>
                                    {school.city},{" "}
                                    {USStateToName.get(school.state)}
                                </TableCell>
                                <TableCell>
                                    <LinkIconButton
                                        to={RoutePaths.editSchool(school.id)}
                                    >
                                        <SettingsApplications />
                                    </LinkIconButton>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                ) : (
                    <div style={{ margin: "16px", textAlign: "center" }}>
                        No {isDisplayingArchived ? "archived" : "active"}{" "}
                        schools found
                    </div>
                )}
            </Table>
        </div>
    )
})
